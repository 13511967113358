import React from "react"

import { GatsbyImage } from "gatsby-plugin-image"
import { renderRule, StructuredText } from "react-datocms"

import { isLink } from "react-datocms/node_modules/datocms-structured-text-utils"

const PartnerGallery = ({ text, logos, description, greyLogo, content }) => {
  const getGreyLogo = greyLogo => {
    if (greyLogo) {
      return "grayscale(100%)"
    } else return null
  }

  return (
    <div className="w-full space-y-4">
      {text && (
        <h2 className="py-2 text-3xl font-semibold text-primary-600">{text}</h2>
      )}

      {logos && (
        <div className="flex w-full items-center justify-center gap-4 px-2">
          {logos.map(logo => (
            <GatsbyImage
              style={{ objectFit: "scale-down" }}
              imgStyle={{
                filter: getGreyLogo(greyLogo),
                objectFit: "scale-down",
              }}
              image={logo.gatsbyImageData}
            />
          ))}
        </div>
      )}
      {content && (
        <StructuredText
          data={content}
          customNodeRules={[
            renderRule(isLink, ({ node, children }) => {
              return (
                <a
                  href={node.url}
                  className="cursor-pointer font-semibold underline hover:text-primary-600"
                >
                  {children}
                </a>
              )
            }),
          ]}
        />
      )}
    </div>
  )
}

export default PartnerGallery

import { graphql } from "gatsby"
import React from "react"
import AboutFeatures from "../components/features"
import Layout from "../components/layout"
import PageStartBanner from "../components/PageStartBanner"
import PartnerGallery from "../components/partnerGallery"
import TwoColumnProduct from "../components/TwoColumnProduct"

const AboutPage = ({
  pageContext,
  data: {
    datoCmsAboutPage: {
      seo,
      hero,
      history,
      partner,
      coreValues,
      head,
      fiere,
      year,
    },
  },
}) => {
  const schema = {
    "@context": "https://schema.org",
    "@type": "Corporation",
    name: "MoviLift",
    url: "https://www.movilift.com",
    description: seo?.seo?.seoDescription,
    logo: seo?.seo?.image?.seoImageUrl,
    sameAs: [
      "https://www.facebook.com/movilift/",
      "https://www.instagram.com/moviliftitalia/",
    ],
  }
  return (
    <Layout
      pageData={pageContext}
      seoTitle={seo?.seoTitle}
      seoDescription={seo?.seoDescription}
      seoImage={seo?.image?.seoImageUrl}
      schemaMarkup={schema}
    >
      <PageStartBanner title={head[0].title} tag={head[0].tag} />

      <TwoColumnProduct
        isHero
        title={hero[0].title}
        heroimage={hero[0].image?.gatsbyImageData}
        bottomImage
        structuredData={hero[0].content}
      />

      <div className="flex w-full flex-col items-center justify-center gap-8">
        <div
          style={{
            height: "50px",
            backgroundColor: "#0b99cd",
            width: "4px",
          }}
        />
        <h2 className="text-6xl font-bold text-primary-600">
          {year[0].number}
        </h2>
        <p className="text-lg">{year[0].subtext}</p>
        <div
          style={{
            height: "50px",
            backgroundColor: "#0b99cd",
            width: "4px",
          }}
        />
      </div>

      <TwoColumnProduct
        title={history[0].title}
        heroimage={history[0].image?.gatsbyImageData}
        reverse={true}
        structuredData={history[0].content}
      />
      <AboutFeatures features={coreValues} />
      <div className="mx-auto my-8 flex w-full max-w-screen-xl flex-col items-center justify-center gap-8 px-4">
        <PartnerGallery
          text={partner[0].title}
          logos={partner[0].logos}
          content={partner[0].content}
          description={partner[0].description}
        />
        <PartnerGallery
          text={fiere[0].title}
          logos={fiere[0].logos}
          content={fiere[0].content}
          description={fiere[0].description}
        />
      </div>
    </Layout>
  )
}

export default AboutPage

export const query = graphql`
  query AboutPageQuery($locale: String!) {
    datoCmsAboutPage(locale: { eq: $locale }) {
      locale
      id: originalId
      slug
      title
      hero {
        title

        content {
          value
        }
        image {
          gatsbyImageData(height: 350)
        }
      }
      head {
        title
        tag
      }
      history {
        title

        content {
          value
        }
        image {
          gatsbyImageData(height: 350)
        }
      }
      year {
        number
        subtext
      }
      partner {
        title
        logos {
          gatsbyImageData(height: 200, width: 200)
        }
        description
        content {
          value
        }
      }
      fiere {
        title
        logos {
          gatsbyImageData(height: 200, width: 200)
        }
        description
        content {
          value
        }
      }
      coreValues {
        id

        title
        icon {
          gatsbyImageData(height: 350)
          url
        }
        content {
          value
          links {
            __typename
            id: originalId
            title
            slug
          }
        }
      }
      seo {
        seoTitle: title
        seoDescription: description
        seoImage: image {
          seoImageUrl: url
        }
      }
    }
  }
`

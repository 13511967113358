import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import { StructuredText } from "react-datocms"
import { Navigator } from "./LanguageHelpers/Navigator"

const AboutFeatures = ({ features }) => {
  return (
    <div className="my-10 mx-auto grid max-w-screen-xl grid-cols-1 gap-8 p-4 md:grid-cols-2">
      {features.map((feature, i) => (
        <div
          className="flex flex-1 flex-col items-start space-y-4"
          key={feature.id}
        >
          {feature.icon && feature.icon.gatsbyImageData ? (
            <GatsbyImage
              alt=""
              image={feature.icon.gatsbyImageData}
              className="h-16 w-16 object-contain"
            />
          ) : (
            <img
              alt=""
              src={feature.icon?.url}
              style={{
                height: "64px",
                width: "64px",
                marginBottom: "10px",
                filter:
                  " brightness(0) saturate(100%) invert(41%) sepia(65%) saturate(2884%) hue-rotate(169deg) brightness(98%) contrast(91%)",
              }}
            />
          )}
          <h2 className="text-3xl font-semibold text-primary-600">
            {feature.title}
          </h2>
          {feature.description && <p>{feature.description}</p>}
          {feature.content ? (
            <StructuredText
              data={feature.content}
              renderInlineRecord={({ record }) => {
                switch (record.__typename) {
                  case "DatoCmsElevator":
                    return (
                      <Navigator recordId={record.id}>{record.title}</Navigator>
                    )
                  default:
                    return null
                }
              }}
            />
          ) : null}
          {feature.value && <p>{feature.value}</p>}
        </div>
      ))}
    </div>
  )
}

export default AboutFeatures
